<template>
  <q-form ref="editForm">
    <!-- 교육과정 정보 -->
    <c-card title="LBL0002876" class="cardClassDetailForm">
      <template slot="card-button">
        <q-btn-group outline >
          <!-- QR 코드 -->
          <!-- <c-qr-btn
            v-if="editable && educationInfo.documentStatusCd && educationInfo.documentStatusCd !== 'ESC000001'"
            :mobileUrl="'/sop/edu/result/educationResult?eduEducationId='+educationInfo.eduEducationId"
          ></c-qr-btn> -->
          <template v-if="!checkAppr">
            <font class="text-negative" style="font-size:0.9em;font-weight:500; margin-right: 10px;">
              <!-- ※ 연간교육계획에 수립된 교육입니다. 연간교육계획 결재가 승인 된 후 계획완료 기능을 이용하실 수 있습니다. -->
              {{$message('MSG0000864')}}
            </font>
          </template>
          <c-update-btn 
            name="updateBtn"
            :data="param.planUpdateBtnData"
            :btnEditable="btnEditable"
            :flagCondition="flagCondition"
            @back="back"
          />
          <!-- <c-btn  v-show="editable&&!disabled" :showLoading="false" label="교육계획결재" icon="save" color="blue" @btnClicked="planAppr" /> -->
          <!-- 삭제 -->
          <c-btn v-show="editable&&param.eduEducationId&&!disabled&&checkAppr&&educationInfo.documentStatusCd=='ESC000001'" label="LBLREMOVE" :editable="editable" icon="delete_forever" @btnClicked="remove" />
          <c-btn
            v-if="editable && !disabled"
            :url="saveUrl"
            :isSubmit="isSave"
            :param="educationInfo"
            :mappingType="saveType"
            label="저장"
            icon="save"
            @beforeAction="saveEducation"
            @btnCallback="saveCallback" 
          />
          <!-- 완료 -->
          <c-btn  v-show="editable&&!disabled&&educationInfo.eduEducationId&&educationInfo.documentStatusCd=='ESC000001'" 
            :showLoading="false" icon="check" label="LBLCOMPLETE"  
            :isSubmit="isComplete"
            :url="completeUrl"
            :param="educationInfo"
            mappingType="PUT"
            @beforeAction="planComplete('COMPLETE')" 
            @btnCallback="planCompleteCallback" />
        </q-btn-group>
      </template>
      <template slot="card-detail">
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <c-text
            :afterIcon="disabled ? null :[
              { name: 'search', click: true, callbackName: 'searchEduMaster'},
              { name: 'close', click: true, callbackName: 'removeEduMaster' }
            ]"
            :required="true" 
            :readonly="true"
            :close="true"
            :editable="editable"
            label="교육과정"
            name="educationCourseName"
            @searchEduMaster="searchEduMaster"
            @removeEduMaster="removeEduMaster"
            v-model="educationInfo.educationCourseName">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-select
            :editable="editable"
            :disabled="true"
            type="edit"
            codeGroupCd="EDU_KIND_FST_CD"
            itemText="codeName"
            itemValue="code"
            name="educationKindCdLarge"
            label="교육종류1"
            v-model="educationInfo.educationKindCdLarge"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-select
            :disabled="true"
            :editable="editable"
            type="edit"
            codeGroupCd="EDU_KIND_SEC_CD"
            itemText="codeName"
            itemValue="code"
            name="educationKindCdSmall"
            label="교육종류2"
            v-model="educationInfo.educationKindCdSmall"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-select
            :disabled="true"
            :editable="editable"
            type="edit"
            codeGroupCd="EDU_CLASS_CD"
            itemText="codeName"
            itemValue="code"
            name="educationTypeCd"
            label="교육구분"
            v-model="educationInfo.educationTypeCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-1 col-lg-1 col-xl-1">
          <c-checkbox
            :disabled="true"
            :isFlag="true"
            :editable="editable"
            type="edit"
            label="법정교육여부"
            name="legalEducationFlag"
            v-model="educationInfo.legalEducationFlag">
          </c-checkbox>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-1 col-lg-1 col-xl-1">
            <!-- v-show="educationInfo.legalEducationFlag==='Y'?true:false" -->
          <c-select
            :editable="false"
            type="edit"
            codeGroupCd="EDU_CYCLE_CD"
            itemText="codeName"
            itemValue="code"
            name="educationCycle"
            label="법정교육주기"
            v-model="educationInfo.educationCycle"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <c-multi-select
            :disabled="true"
            :editable="editable"
            :isArray="false"
            codeGroupCd="RELATED_LAWS_CD"
            itemText="codeName"
            itemValue="code"
            label="관련법규"
            name="relationLaws"
            maxValues="3"
            v-model="educationInfo.relationLaws">
          </c-multi-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-text
            :disabled="true"
            :editable="editable"
            label="주요 대상자"
            name="mainTargetAudience"
            v-model="educationInfo.mainTargetAudience">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
          <c-textarea
            :disabled="true"
            :editable="editable"
            :rows="1"
            label="학습목적"
            name="educationPurpose"
            v-model="educationInfo.educationPurpose">
          </c-textarea>
        </div>
      </template>
    </c-card>
    <c-card title="교육계획 기본정보" class="cardClassDetailForm">
      <template slot="card-detail">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-text
            required
            :disabled="disabled"
            :editable="editable"
            label="교육명"
            name="educationName"
            v-model="educationInfo.educationName">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-dept
            :editable="editable"
            :required="true" 
            :disabled="disabled"
            :plantCd="educationInfo.plantCd"
            :data="educationInfo"
            label="주관부서"
            name="departmentDeptCd"
            v-model="educationInfo.departmentDeptCd">
          </c-dept>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-1 col-lg-1 col-xl-1">
          <!-- true: 기간, false: 일 -->
          <c-checkbox
            :disabled="disabled"
            :editable="editable"
            :isFlag="true"
            label="기간/일"
            trueLabel="기간"
            falseLabel="일"
            name="educationRangeFlag"
            v-model="educationInfo.educationRangeFlag"
            @datachange="datachange"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 연간교육계획이 결재중인 경우 교육일시 수정 불가 -->
          <c-datepicker
            v-show="isDate"
            :required="isDate"
            :disabled="disabled || yearEduAppr"
            :editable="editable"
            label="교육기간"
            :range="true"
            type="date"
            name="educationPeriod"
            v-model="educationPeriod"
            @input="updateEduNamePeriod">
          </c-datepicker>
          <c-datepicker
            v-show="!isDate"
            :required="!isDate"
            :disabled="disabled || yearEduAppr"
            :editable="editable"
            label="교육일"
            type="date"
            name="educationDate"
            v-model="educationInfo.educationStartDate"
            @input="updateEduNameDate">
          </c-datepicker>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-datepicker
            required
            :disabled="disabled"
            :editable="editable"
            label="교육시간"
            type="time"
            :range="true"
            :minuteStep="10"
            name="eduPlanTime"
            v-model="param.eduPlanTime"
            @input="updateTime">
          </c-datepicker>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-plant 
            :required="true" 
            :editable="editable" 
            :disabled="disabled"
            type="edit" 
            name="plantCd" 
            v-model="educationInfo.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-text
            :disabled="disabled"
            :editable="editable"
            label="교육장소"
            name="educationLocation"
            v-model="educationInfo.educationLocation">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 예상 교육비 (단위: 원) -->
          <c-text
            :suffix="$label('LBL0002813')"
            type="number"
            :editable="editable"
            :disabled="disabled"
            label="예상교육비"
            name="executionBudget"
            v-model="educationInfo.executionBudget">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-risk-assess
            :editable="editable"
            :disabled="disabled"
            label="관련 3단계판단법"
            name="ramRiskAssessmentPlanId"
            v-model="educationInfo.ramRiskAssessmentPlanId">
          </c-risk-assess>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-select
            :disabled="true"
            :editable="editable"
            type="edit"
            :comboItems="comboItems"
            itemText="codeName"
            itemValue="code"
            itemDisable="disable"
            name="educationMethodCd"
            label="교육방법"
            v-model="educationInfo.educationMethodCd"
            @datachange="educationMethodChange"
          >
            <!-- <template v-slot:suffixLabel>
              <q-icon name="info" color="orange" style="font-size: 1.8em; margin-left: 10px;">
                <q-tooltip anchor="center right" self="center left" :offset="[10, 10]" content-style="font-size: 0.9em" >
                  '교육계획 관련파일' 섹션에서 동영상 파일 첨부시 동영상 선택 가능
                </q-tooltip>
              </q-icon>
            </template> -->
        </c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            :disabled="disabled || educationInfo.educationMethodCd != 'EMC999999'"
            :editable="editable"
            :comboItems="educationVodItems"
            type="edit"
            itemText="codeName"
            itemValue="code"
            name="vod"
            label="교육용 동영상"
            v-model="educationInfo.vod"
          ></c-select>
        </div>
        <!-- <div class="col-xs-12 col-sm-12 col-md-1 col-lg-1 col-xl-1">
          <c-checkbox
            :disabled="true"
            :editable="editable"
            :isFlag="true"
            label="문제풀이여부"
            name="eduQuestionFlag"
            v-model="educationInfo.eduQuestionFlag"
            @input="input">
          </c-checkbox>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-edu-quiz 
            :disabled="true"
            :required="isQuestion"
            :editable="editable"
            label="문제풀이SET"
            name="eduQuestionMstId"
            v-model="educationInfo.eduQuestionMstId"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-1 col-lg-1 col-xl-1">
          <c-text
            :disabled="disabled || !isQuestion"
            :required="isQuestion"
            :editable="editable"
            type="number"
            label="통과기준점수"
            name="eduQuestionPassScore"
            v-model="educationInfo.eduQuestionPassScore">
          </c-text>
        </div> -->
      </template>
    </c-card>
    
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 attachs">
        <c-upload 
          :attachInfo="attachEduPlanInfo"
          :editable="editable&&!disabled"
          label="교육계획 관련 파일"
          >
        </c-upload>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 attachs">
        <c-upload 
          :attachInfo="attachEduCourseInfo"
          :editable="false"
          label="교육과정 관련 파일" 
          @files="files">
        </c-upload>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <c-table
          ref="subjectTable"
          title="교육내용"
          :columns="grid.columns"
          :gridHeight="userGrid.height"
          :data="educationInfo.eduSubjectList"
          :filtering="false"
          :columnSetting="false"
          :usePaging="false"
          :hideBottom="true"
          :isFullScreen="false"
          :isExcelDown="false"
          noDataLabel="교육내용이 없습니다."
          :editable="editable && !disabled"
          rowKey="subjectNo"
          selection="multiple"
        >
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn v-if="editable && !disabled" label="추가" :showLoading="false"  icon="add" @btnClicked="addSubject" />
              <c-btn v-if="editable && !disabled && educationInfo.eduSubjectList.length > 0" label="제외" :showLoading="false" icon="remove" @btnClicked="removeSubject" />
            </q-btn-group>
          </template>
          <template v-if="educationInfo.eduSubjectList && educationInfo.eduSubjectList.length>0" slot="suffixTitle">
            <font class="text-negative" style="font-size:0.8em;font-weight:300;">
              ※ 총 교육소요시간 : 
            </font>
            <q-chip
              outline square 
              color="primary"
              text-color="white"
              class="q-ma-none customchipdept">
              <template>
                {{ formattedEducationTime }}
              </template>
            </q-chip>
          </template>
        </c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <c-table
          ref="userTable"
          title="교육대상자 목록"
          :columns="userGrid.columns"
          :gridHeight="userGrid.height"
          :data="educationInfo.eduAttendeeList"
          :filtering="false"
          :isFullScreen="false"
          :columnSetting="false"
          :usePaging="false"
          :isExcelDown="false"
          :hideBottom="true"
          :editable="editable && !disabled"
          noDataLabel="교육 대상자가 없습니다."
          rowKey="attendeesNo"
          selection="multiple"
        >
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn label="추가" icon="add" v-if="editable && !disabled" :showLoading="false" @btnClicked="addInUser" />
              <!-- <c-btn label="직접 추가" icon="add" v-if="editable && !disabled" :showLoading="false" @btnClicked="addExUser" /> -->
              <c-btn v-if="editable && !disabled && educationInfo.eduAttendeeList.length > 0" label="제외" :showLoading="false" icon="remove" @btnClicked="removeUser" />
            </q-btn-group>
          </template>
          <template slot="suffixTitle">
            <font class="text-negative" style="font-size:0.8em;font-weight:300;">
              ※ 교육대상자 등록은 필수입니다. 
            </font>
          </template>

          <template v-slot:customArea="{ props, col }">
            <template v-if="col.name ==='attendeesDeptName'">
              <c-text-column
                v-if="props.row['inExTypeCd'] === 'EX'"
                :editable="editable"
                :props="props"
                :col="col"
                :disabled="disabled"
                v-model="props.row['attendeesDeptName']"
                @datachange1="datachange1(props)"
              />
              <span v-else>
                {{props.row['attendeesDeptName']}}
              </span>
            </template>
            <template v-if="col.name ==='attendeesPositionName'">
              <c-text-column
                v-if="props.row['inExTypeCd'] === 'EX'"
                :editable="editable"
                :props="props"
                :col="col"
                :disabled="disabled"
                v-model="props.row['attendeesPositionName']"
                @datachange1="datachange1(props)"
              />
              <span v-else>
                {{props.row['attendeesPositionName']}}
              </span>
            </template>
            <template v-if="col.name ==='attendeesName'">
              <c-text-column
                v-if="props.row['inExTypeCd'] === 'EX'"
                :editable="editable"
                :props="props"
                :col="col"
                :disabled="disabled"
                v-model="props.row['attendeesName']"
                @datachange1="datachange1(props)"
              />
              <span v-else>
                {{props.row['attendeesName']}}
              </span>
            </template>
          </template> 

        </c-table>
      </div>
    </div>

  <c-dialog :param="popupOptions"></c-dialog>
  </q-form>
</template>
<script>

import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'education-plan-info',
  props: {
    param: {
      type: Object,
      default: () => ({
        eduEducationId: '',
        plantCd: null,
        eduPlanTime: [],
        planUpdateBtnData: {
          title: 'LBLPLAN',
          flag: false,
          research: '',
          planResearch: '',
        },
      }),
    },
    educationDate: {
      type: String,
      default: '',
    },
    educationInfo: {
      type: Object,
      default: () => ({
        eduEducationId: '',
        eduEducationYearPlanId: '',
        educationName: '',
        eduCourseId: '',
        plantCd: '',
        educationYear: '',
        educationCourseName: '',
        educationKindCdLarge: null,
        educationKindCdSmall: null,
        educationTypeCd: null,
        departmentDeptCd: '',
        legalEducationFlag: null,
        educationCycle: null,
        educationPurpose: '',
        relationLaws: '',
        mainTargetAudience: '',
        educationMonth: null,
        educationLocation: '',
        executionBudget: '',
        educationStartDate: '',
        educationEndDate: '',
        educationStartTime: '',
        educationEndTime: '',
        instructorInformation: '',
        sopMocId: '',
        educationMethodCd: null,
        educationOverview: '',
        evaluationEvalCd: '',
        documentStatusCd: '',
        qrCodeRoute: '',
        educationRangeFlag: 'N',
        eduQuestionFlag: 'N',
        eduQuestionMstId: '',
        eduQuestionPassScore: 0,
        checkAppr: '',
        regUserId: '',
        chgUserId: '',  
        vod: null,
        sumEducationTime: '',
        // 위험성평가 결과 항목 추가
        ramRiskAssessmentPlanId: '',

        // 자체감사 항목 추가
        selfInspectionResultId: '',
        selfInspectionStatusCd: '',
        sysRevision: '',
        selfInspectionTitle: '',

        eduSubjectList: [], // 과정별 교육과목
        delEduSubjectList: [],
        eduAttendeeList: [], // 대상자
        delEduAttendeeList: [],

        approvalStatusCd: '', // 결재관련 결재상태
        sysApprovalRequestId: '', // 결재관련 결재요청코드
      }),
    },
    saveCall: {
      type: Object,
      default: () => ({
        saveCallData: '',
      }),
    },
  },
  data() {
    return {
      educationVodItems: [],
      editable: true,
      flagItems: [
        { code: 'Y', codeName: '해당' },
        { code: 'N', codeName: '해당없음' },
      ],
      grid: {
        columns: [
          {
            required: true,
            name: 'subjectName',
            field: 'subjectName',
            label: '과목 및 세부내용',
            align: 'left',
            // style: 'width:70%',
            type: 'text',
            sortable: false,
          },
          // {
          //   name: 'subjectDetailName',
          //   field: 'subjectDetailName',
          //   label: '세부내용',
          //   style: 'width:100px',
          //   align: 'left',
          //   type: 'text',
          //   sortable: false,
          // },
          {
            name: 'educationTime',
            field: 'educationTime',
            label: '교육소요시간',
            type: 'datetime',
            minuteStep: 10,
            style: 'width:100px',
            align: 'center',
            sortable: false
          },
          {
            name: 'instructorName',
            field: 'instructorName',
            type: 'text',
            label: '강사명',
            style: 'width:120px',
            align: 'left',
            sortable: false
          },
        ],
        height: '200px'
      },
      userGrid: {
        columns: [
          {
            name: 'attendeesName',
            field: 'attendeesName',
            label: '대상자',
            // style: 'width:25%',
            align: 'center',
            sortable: false,
            type: 'custom',
          },
          {
            name: 'attendeesDeptName',
            field: 'attendeesDeptName',
            style: 'width:25%',
            label: '소속/부서',
            align: 'center',
            sortable: false,
            type: 'custom'
          },
          {
            name: 'attendeesPositionName',
            field: 'attendeesPositionName',
            style: 'width:25%',
            label: '직책',
            align: 'center',
            sortable: false,
            type: 'custom',
          },
          {
            name: 'seniorityName',
            field: 'seniorityName',
            style: 'width:25%',
            label: '선임명',
            align: 'center',
            sortable: false,
          },
        ],
        height: '300px'
      },
      title: '',
      selectDate: '',
      isOpen: false,
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
      saveUrl: '',
      saveType: 'POST',
      isSave: false,
      getUrl: '',
      insertUrl: '',
      deleteUrl: '',
      updateUrl: '',
      completeUrl: '',
      isComplete: false,
      updateMode: false,
      newDate: ['', ''],
      newTime: ['', ''],
      attachEduPlanInfo: {
        isSubmit: '',
        taskClassCd: 'EDU_PLAN',
        taskKey: '',
      },
      attachEduCourseInfo: {
        isSubmit: '',
        taskClassCd: 'EDU_CURRICULUM',
        taskKey: '',
      },
      eduPlanTime: [], //교육시간
      comboItems: [],
    };
  },
  computed: {
    checkAppr() {
      // true 인 경우 쓰기가능
      return this.educationInfo.eduEducationYearPlanId ? this.educationInfo.checkAppr === 'ASC9999999' : true;
    },
    flagCondition() {
      return this.educationInfo.documentStatusCd === 'ESC000001'
    },
    btnEditable() {
      return this.editable && this.educationInfo.documentStatusCd === 'ESC000005' && Boolean(this.educationInfo.eduEducationId)
    },
    disabled() {
      return (Boolean(this.educationInfo.documentStatusCd) 
        && this.educationInfo.documentStatusCd !== 'ESC000001'
        && !this.param.planUpdateBtnData.flag)
        || this.educationInfo.approvalStatusCd === 'ASC0000001'
    },
    yearEduAppr() {
      // 연간교육계획이 결재중인지 여부 체크
      return this.educationInfo.checkAppr === 'ASC0000001'
    },
    isDate() {
      return this.educationInfo.educationRangeFlag === 'Y'
    },
    educationPeriod: {
      get() {
        if (this.educationInfo.educationStartDate && this.educationInfo.educationEndDate) {
          return [this.educationInfo.educationStartDate, this.educationInfo.educationEndDate]
        } else {
          return this.newDate;
        }
      },
      set(newDate) {
        if (newDate && newDate.length > 0) {
          this.educationPeriod[0] = newDate[0]
          this.educationPeriod[1] = newDate[1]
        } 
        this.newDate = newDate;
      }
    },
    isQuestion() {
      return this.educationInfo.eduQuestionFlag == 'Y'
    },
    // 총 교육소요시간 계산을 수행하는 계산 속성
    formattedEducationTime() {
      let totalMinutes = 0;
      if(this.educationInfo.eduSubjectList && this.educationInfo.eduSubjectList.length>0) {
        this.educationInfo.eduSubjectList.forEach(item => {
        if (item.educationTime) {
          const [hours, minutes] = item.educationTime.split(":").map(part => parseInt(part, 10));
          totalMinutes += (hours * 60) + minutes;
        }
      });
      }
      const totalHours = Math.floor(totalMinutes / 60);
      const remainingMinutes = totalMinutes % 60;
      // 계산된 총 시간을 표시, 앞에 '0'을 붙여 두 자리 숫자로 표시
      const formattedHours = totalHours.toString().padStart(2, '0');
      const formattedMinutes = remainingMinutes.toString().padStart(2, '0');
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.educationInfo.sumEducationTime = `${formattedHours}:${formattedMinutes}`;
      return this.educationInfo.sumEducationTime; // 계산된 총 시간을 반환
    }
  },
  watch: {
    'param.eduEducationId'() {
      this.setTaskKey();
    },
    'educationInfo.eduCourseId'() {
      this.setTaskKey2();
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      this.saveUrl = transactionConfig.sop.edu.plan.insert.url;
      this.insertUrl = transactionConfig.sop.edu.plan.insert.url;
      this.updateUrl = transactionConfig.sop.edu.plan.update.url;
      this.deleteUrl = transactionConfig.sop.edu.plan.delete.url;
      this.completeUrl = transactionConfig.sop.edu.annual.complete.url;
      this.setTaskKey();
      this.setTaskKey2();

      this.$comm.getComboItems('EDU_METHOD_CD').then(_result => {
        if(_result && _result.length>0) {
          this.$_.forEach(_result, _item => {
            if (_item.attrVal1 == 'Y') _item.disable = true
            else _item.disable = false
          })
          this.comboItems = _result
        }
      });
    },
    updateEduNameDate(value) {
      this.educationInfo.educationName = this.educationInfo.educationName.split(' | ')[0] + ' | '+value
      this.educationInfo.educationStartDate = value
      this.educationInfo.educationEndDate = value
    },
    updateEduNamePeriod(value) {
      this.educationInfo.educationName = this.educationInfo.educationName.split(' | ')[0] + ' | '+value[0]
      this.educationInfo.educationStartDate = value[0]
      this.educationInfo.educationEndDate = value[1]
    },
    updateTime(value){
      this.educationInfo.educationStartTime = value[0]
      this.educationInfo.educationEndTime = value[1]
    },
    educationMethodChange() {
      if (this.educationInfo.educationMethodCd !== 'EMC999999') {this.educationInfo.vod = null} 
    },
    files(fileList) {
      this.educationVodItems = [];
      let hasMP4 = false; // MP4 파일이 존재하는지 여부를 나타내는 플래그

      if(fileList && fileList.length>0) {
        this.$_.forEach(fileList, _item => {
          if (_item.contentType === 'video/mp4') {
            this.educationVodItems.push({
              code: _item.sysAttachFileId,
              codeName: _item.oriFileNm,
            });
            hasMP4 = true; // MP4 파일이 있으면 플래그를 true로 설정
          }
        });
      }
      if(this.comboItems && this.comboItems.length>0) {
        if (hasMP4) { // MP4 파일이 있는 경우 모든 comboItems를 활성화
          this.$_.forEach(this.comboItems, _item => {_item.disable = false});
        } else { // MP4 파일이 없는 경우 특정 조건에 따라 comboItems 활성화/비활성화
          this.$_.forEach(this.comboItems, _item => { _item.disable = _item.attrVal1 === 'Y'});
        }
      }
      
    },
    saveEducation() {
      this.saveUrl = this.param.eduEducationId ? this.updateUrl : this.insertUrl
      this.saveType = this.param.eduEducationId ? 'PUT' : 'POST'
      this.$refs['editForm'].validate().then(_result => {
        if (_result && this.educationInfo.eduSubjectList && this.educationInfo.eduSubjectList.length > 0
          && this.educationInfo.eduAttendeeList && this.educationInfo.eduAttendeeList.length > 0
        ) { window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?', 
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              if (this.param.eduEducationId){this.educationInfo.chgUserId = this.$store.getters.user.userId;
              } else {this.educationInfo.regUserId = this.$store.getters.user.userId;}

              if(this.educationInfo.eduSubjecList && this.educationInfo.eduSubjecList.length>0) {
                this.$_.forEach(this.educationInfo.eduSubjectList, _item => {
                  if (this.param.eduEducationId) _item.chgUserId = this.$store.getters.user.userId
                  else _item.regUserId = this.$store.getters.user.userId
                })
              }
              if(this.educationInfo.eduAttendeeList && this.educationInfo.eduAttendeeList.length>0) {
                this.$_.forEach(this.educationInfo.eduAttendeeList, _item => {
                  if (this.param.eduEducationId) _item.chgUserId = this.$store.getters.user.userId
                  else _item.regUserId = this.$store.getters.user.userId
                })
              }
              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => { },
          });
        } else {window.getApp.$emit('APP_VALID_ERROR');}
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.param.eduEducationId = result.data;
      this.saveCall.saveCallData = uid();
      this.$emit('setData', result.data)
    },
    addSubject() {
      this.educationInfo.eduSubjectList.splice(0, 0, {
        eduEducationId: this.param.eduEducationId,
        subjectNo: uid(),
        subjectName: '', // 교육명
        // subjectDetailName: '', // 세부내용
        educationTime: '01:00', // 교육시간
        instructorName: '', // 강사명
        regUserId: this.$store.getters.user.userId,
        editFlag: 'C',
      })
    },
    removeSubject() {
      let selectData = this.$refs['subjectTable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '선택된 항목이 없습니다.', 
          type: 'warning', // success / info / warning / error
        });
      } else {
        if (!this.educationInfo.delEduSubjectList) this.educationInfo.delEduSubjectList = []
        if(selectData && selectData.length>0) {
          this.$_.forEach(selectData, item => {
          if (this.$_.findIndex(this.educationInfo.delEduSubjectList, { subjectNo: item.subjectNo }) === -1
            && item.editFlag !== 'C') this.educationInfo.delEduSubjectList.push(item)
            this.educationInfo.eduSubjectList = this.$_.reject(this.educationInfo.eduSubjectList, item)
          })
        }
      }
    },
    searchEduMaster() {
      this.popupOptions.title = '교육과정 검색'; 
      this.popupOptions.param = {
        type: 'single',
        plantCd: this.educationInfo.plantCd,
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/edu/cc/educationCoursePop.vue'}`);
      this.popupOptions.width = '95%';
      this.popupOptions.isFull = false;
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeEduCoursePopup;
    },
    closeEduCoursePopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data.selectData && data.selectData.length > 0) {
        this.$http.url = this.$format(selectConfig.sop.edu.course.get.url, data.selectData[0].eduCourseId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          if (!this.educationInfo.delEduSubjectList) this.educationInfo.delEduSubjectList = []
          if(this.educationInfo.eduSubjecList && this.educationInfo.eduSubjecList.length>0) {
            this.$_.forEach(this.educationInfo.eduSubjectList, item => {
              if (this.$_.findIndex(this.educationInfo.delEduSubjectList, { subjectNo: item.subjectNo }) === -1
                && item.editFlag !== 'C') this.educationInfo.delEduSubjectList.push(item)
              this.educationInfo.eduSubjectList = this.$_.reject(this.educationInfo.eduSubjectList, item)
            })
          }
          // 빈값들 filter
          this.educationInfo.educationCourseName = _result.data.educationCourseName // 교육과정명
          // 교육과정 마스터 데이터 
          this.educationInfo.eduCourseId = _result.data.eduCourseId // 교육과정 key
          this.educationInfo.educationCycle = _result.data.educationCycle // 교육주기
          this.educationInfo.educationKindCdLarge = _result.data.educationKindCdLarge // 교육종류 대
          this.educationInfo.educationKindCdSmall = _result.data.educationKindCdSmall // 교육종류 소
          this.educationInfo.educationTypeCd = _result.data.educationTypeCd // 교육구분
          this.educationInfo.educationPurpose = _result.data.educationPurpose // 학습목적
          this.educationInfo.legalEducationFlag = _result.data.legalEducationFlag // 법정여부
          this.educationInfo.mainTargetAudience = _result.data.mainTargetAudience // 주요대상자
          this.educationInfo.relationLaws = _result.data.relationLaws // 관련법규
          this.educationInfo.executionBudget = _result.data.estimatedEducationExpenses // 예산
          this.educationInfo.eduQuestionFlag = _result.data.eduQuestionFlag
          this.educationInfo.eduQuestionMstId = _result.data.eduQuestionMstId
          this.educationInfo.eduQuestionPassScore = _result.data.eduQuestionPassScore
          this.educationInfo.educationLocation = _result.data.educationLocation
          this.educationInfo.educationMethodCd = _result.data.educationMethodCd
          this.educationInfo.vod = _result.data.vod
          this.educationInfo.educationName = _result.data.educationCourseName

          if (!this.educationInfo.delEduSubjectList) {this.educationInfo.delEduSubjectList = [];}
          if(this.educationInfo.eduSubjectList && this.educationInfo.eduSubjectList.length>0) {
            this.$_.forEach(this.educationInfo.eduSubjectList, (item) => {
              if (this.$_.findIndex(this.educationInfo.delEduSubjectList, {subjectNo: item.subjectNo,}) === -1 
              && item.editFlag !== "C") this.educationInfo.delEduSubjectList.push(item)
            });
            this.educationInfo.eduSubjectList = [];
          }
          if (_result.data.eduCourseSubjectList && _result.data.eduCourseSubjectList.length > 0) {
            this.$_.forEach(_result.data.eduCourseSubjectList, _item => {
              let index = this.$_.findIndex(this.educationInfo.eduSubjectList, {subjectNo: _item.subjectNo,});
              if (index === -1) {
                this.educationInfo.eduSubjectList.push({
                  eduEducationId: this.educationInfo.eduEducationId,
                  subjectNo: _item.subjecNo,
                  subjectName: _item.subjectName, // 교육명
                  educationTime: _item.educationTime, // 교육시간
                  instructorName: _item.instructorName,
                  regUserId: this.$store.getters.user.userId,
                  editFlag: 'C'
                })
              }
            })
          } 

          if (!this.educationInfo.delEduAttendeeList) {this.educationInfo.delEduAttendeeList = [];}
          if(this.educationInfo.eduAttendeeList && this.educationInfo.eduAttendeeList.length>0) {
            this.$_.forEach(this.educationInfo.eduAttendeeList, (item) => {
              if (this.$_.findIndex(this.educationInfo.delEduAttendeeList, {attendeesId: item.attendeesId,}) === -1 && item.editFlag !== "C"
              ) {this.educationInfo.delEduAttendeeList.push(item);}
              this.educationInfo.eduAttendeeList = this.$_.reject(this.educationInfo.eduAttendeeList, item);
            });
            this.educationInfo.eduAttendeeList = [];
          }
          if (_result.data.totalUserList && _result.data.totalUserList.length > 0) {
            this.$_.forEach(_result.data.totalUserList, _item => {
              let index = this.$_.findIndex(this.educationInfo.eduAttendeeList, {attendeesId: _item.userId,});
              if (index === -1) {
                this.educationInfo.eduAttendeeList.push({
                  eduEducationId: this.educationInfo.eduEducationId,
                  attendeesNo: uid(),
                  attendeesId: _item.userId,
                  attendeesTypeCd: 'EATC00003',
                  inExTypeCd: 'COURSE',
                  attendeesPositionName: _item.jobName,
                  attendanceTime: this.educationInfo.sumEducationTime,
                  attendeesDeptName: _item.deptName,
                  seniorityName: _item.seniorityName,
                  enterDate: _item.enterDate,
                  attendeesName: _item.userName,
                  regUserId: this.$store.getters.user.userId,
                  editFlag: 'C'
                })
              }
            })
          } 

        },);
      }
    },
    removeEduMaster() {
      this.init()
    },
    datachange() {
      this.educationInfo.educationStartDate = '';
      this.educationInfo.educationEndDate = '';
      if (this.educationInfo.educationRangeFlag === 'Y') this.educationDate = '';
      else this.educationPeriod = ['', ''];
    },
    addInUser(){
      this.popupOptions.title = "교육 대상자 검색"
      this.popupOptions.param = {
        type: "multiple",
        plantCd: this.educationInfo.plantCd,
      };
      this.popupOptions.target = () => import(`${"@/pages/common/user/userPop.vue"}`);
      this.popupOptions.width = "70%";
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeInUser;
    },
    closeInUser(data){
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, _item => {
          let index = this.$_.findIndex(this.educationInfo.eduAttendeeList, {attendeesName: _item.userName,});
          if (index === -1) {
            this.educationInfo.eduAttendeeList.push({
              eduEducationId: this.param.eduEducationId,
              attendeesId: _item.userId,
              attendeesNo: uid(),
              attendeesTypeCd: 'EATC00003',
              attendeesDeptName: _item.deptName,
              attendeesPositionName: _item.jobName,
              attendeesName: _item.userName,
              inExTypeCd: 'IN',
              regUserId: this.$store.getters.user.userId,
              editFlag: "C",
            })
          }
        })
      }
    },
    removeUser(){
      let selectData = this.$refs["userTable"].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit("ALERT", {
          title: "안내", 
          message: "선택된 항목이 없습니다.", // 
          type: "warning", // success / info / warning / error
        });
      } else {
        if (!this.educationInfo.delEduAttendeeList) this.educationInfo.delEduAttendeeList = []
        if(selectData && selectData.length>0) {
          this.$_.forEach(selectData, (item) => {
            if (this.$_.findIndex(this.educationInfo.delEduAttendeeList, {
                attendeesName: item.attendeesName}) === -1 && item.editFlag !== "C") 
              {this.educationInfo.delEduAttendeeList.push(item);}
            this.educationInfo.eduAttendeeList = this.$_.reject(this.educationInfo.eduAttendeeList, item);
          });
        }
      }
    },
    addExUser(){ // 참석자 직접추가
      this.educationInfo.eduAttendeeList.splice(0, 0, {
        eduEducationId: this.educationInfo.eduEducationId,
        attendeesNo: uid(),
        attendeesDeptName: '',
        attendeesPositionName: '',
        attendeesName: '',
        inExTypeCd: 'EX',
        regUserId: this.$store.getters.user.userId,
        editFlag: "C",
      })
    },
    input(value){
      if(value==='Y') this.educationInfo.eduQuestionPassScore = 80
      else this.educationInfo.eduQuestionPassScore = null
    },
    /**
     * 재조회
     * 목적 : 돌아가기전 데이터를 저장 했을 수도 있지만 
     *        입력만 하고 돌아가는 경우를 대비하기 위함
     */
    back() {
      this.$emit('setData');
    },
    
    planComplete(type) {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: (type === 'COMPLETE') ? 'MSG0000863' : 'MSG0000862',
        // 계획완료하시겠습니까?(저장과 함께 처리됩니다.) // 결재하시겠습니까?(저장과 함께 처리됩니다.)
        // TODO : 점검완료 하시겠습니까?
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.educationInfo.chgUserId = this.$store.getters.user.userId
          this.educationInfo.documentStatusCd = 'ESC000005'
          this.isComplete = !this.isComplete
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    planCompleteCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.saveCall.saveCallData = uid();
      if (this.educationInfo.documentStatusCd === 'ESC000005') {
        this.$emit('stepBack', this.educationInfo)
      }
    },
    /* eslint-disable no-unused-vars */ 
    remove() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: 'MSGREMOVE', // 삭제하시겠습니까?,
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.param.eduEducationId);
          this.$http.type = 'DELETE';
          this.$http.request((_result) => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            
            this.$emit('emitStep', {
              name: 'closePopup',
              param: {},
            })
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    setTaskKey() {
      let taskKey = this.param.eduEducationId ? this.param.eduEducationId : '';
      this.attachEduPlanInfo.taskKey = taskKey
    },
    setTaskKey2() {
      let taskKey = this.educationInfo.eduCourseId ? this.educationInfo.eduCourseId : '';
      this.attachEduCourseInfo.taskKey = taskKey
    },
  }
};
</script>
<style>
.canvas {
  width: 100%;
  height: 100%;
  margin: 3 auto;
  display: inline;
}
.wrap {
  display:flex;
  flex-wrap:wrap;
  padding:20px;
  height:400px;
  box-sizing:border-box;
  border-color: #aaa;
}
.facilityStyle {
  height:200px;
  width:300px;
  display:flex;
  align-items:center;
  justify-content:center;
  flex-wrap:wrap;
}
.abc {
  width:100%;
  height:20px;
  display:inline;
}
</style>
<style lang="sass">
.attachs 
  .upload-cover
    margin-top: -5px !important
    height: auto !important
    .custom-upload, .uploaded-file-list
      min-height: 150px !important
      max-height: 150px !important
      .fileuploadItem
        height: auto !important
</style>